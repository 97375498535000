<template>
    <div
        class="d-flex flex-column flex-fill no-scroll"
        style="overflow: hidden;"
        :class="[{'pa-5': !windowed, 'px-5 pb-5': windowed}]"
    >
        <div class="d-flex align-center">
            <fe-tooltip v-if="!windowed" tooltip="Back" bottom>
                <fe-icon-btn
                    useIcon="fas fa-chevron-left"
                    style="margin-top: -5px"
                    @click="goBack"
                    id="incidents-charting-back-btn"
                />
            </fe-tooltip>

            <span style="font-size: 24px" v-if="!windowed">Incident Charting</span>

            <fe-tooltip
                v-if="!windowed"
                tooltip="Click a data point to drilldown for an overview of incident details.
                To zoom, click and drag within the chart."
                direction="right"
                block
            >
                <v-icon class="ml-4" color="#C0C3CF" style="cursor: pointer">fas fa-question-circle</v-icon>
            </fe-tooltip>

            <v-spacer/>
        </div>

        <div class="d-flex align-stretch">
            <fe-filter-btn
                title="Year"
                v-model="schoolYear"
                url="qry.php?property=school_years"
                rootProperty="years"
                itemValue="id"
                itemText="name"
                :closeOnSelect="false"
                multiple
                showFirstSelection
                data-test="launchpad-incidents-overlay-filter-year"
            />

            <v-menu
                v-model="dateMenuOpen"
                content-class="fe-filter-btn-menu"
                :close-on-content-click="false"
                :close-on-click="!customDateRange"
                :nudge-bottom="5"
                offset-y
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        rounded
                        depressed
                        color="#E0E1E6"
                        :class="ifMenuOpenClass"
                        :style="cssVars"
                        data-test="launchpad-incidents-overlay-filter-date-range"
                    >
                        {{ dateRangeActive ? dateRangeActive : 'Date Range' }}
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                        v-for="(item, i) in dateRangeOptions"
                        :key="`date-range-option-${i}`"
                        :class="{'last-option': item.last}"
                        @click="item.handler()"
                    >
                        <span>{{ item.name }}</span>
                    </v-list-item>
                </v-list>
            </v-menu>

            <div style="position: relative">
                <div v-if="customDateRange" class="calendar" :class="{'existing-range': dateRange}">
                    <functional-calendar
                        class="lean-calendar"
                        v-model="dateRange"
                        dateFormat="mm/dd/yyyy"
                        @input="selectedDateRange = $event"
                        is-date-range
                    />

                    <v-divider/>

                    <div class="d-flex" style="padding: 4px 0">
                        <v-spacer/>

                        <fe-btn
                            usage="primary"
                            :disabled="!selectedDateRange.dateRange.start || !selectedDateRange.dateRange.end"
                            @click="setDateRange()"
                        >
                            Apply
                        </fe-btn>
                    </div>
                </div>
            </div>

            <fe-filter-btn
                title="School"
                v-model="school"
                ref="school_id-filter-menu"
                rootProperty="schools"
                :url="schoolURL"
                :disabled="!schoolYear.included"
                :showApplyButton="false"
                :closeOnSelect="false"
                showFirstSelection
                showClearButton
                data-test="launchpad-incidents-overlay-filter-school"
                @clear="removeFilter('school_id')"
            />

            <fe-filter-btn
                title="Grade"
                v-model="grade"
                rootProperty="grades"
                :url="gradeURL"
                :disabled="!schoolYear.included"
                :showApplyButton="false"
                :closeOnSelect="false"
                showFirstSelection
                showClearButton
                data-test="launchpad-incidents-overlay-filter-grade"
                ref="grade_id-filter-menu"
                @clear="removeFilter('grade_id')"
            />

            <fe-filter-btn
                title="Type"
                v-model="incidentType"
                rootProperty="incident_behavior_type"
                :url="$models.getUrl('incidentBehaviorType', 'read')"
                :showApplyButton="false"
                :closeOnSelect="false"
                showFirstSelection
                showClearButton
                data-test="launchpad-incidents-overlay-filter-type"
                ref="incident_type_id-filter-menu"
                @clear="removeFilter('incident_type_id')"
            />

            <fe-filter-btn
                title="Code"
                v-model="incidentBehavior"
                rootProperty="incident_behavior"
                :url="$models.getUrl('incidentBehavior', 'read')"
                :showApplyButton="false"
                :closeOnSelect="false"
                showFirstSelection
                showClearButton
                data-test="launchpad-incidents-overlay-filter-code"
                ref="incident_behavior_type_id-filter-menu"
                @clear="removeFilter('incident_behavior_type_id')"
            />

            <fe-filter-btn
                title="Response"
                v-model="incidentResponse"
                rootProperty="incident_responses"
                :url="$models.getUrl('incidentResponse', 'read')"
                :showApplyButton="false"
                :closeOnSelect="false"
                showFirstSelection
                showClearButton
                data-test="launchpad-incidents-overlay-filter-response"
                ref="incident_response_id-filter-menu"
                @clear="removeFilter('incident_response_id')"
            />

            <fe-btn usage="secondary" @click="advancedSearch = true">
                {{ filterButtonText }}
            </fe-btn>
        </div>

        <filter-chip
            :quickFilters="quickFilters"
            :moreFilters="moreFilters"
            :updateChips="updateChips"
            @clearAllFilters="clearAllFilters"
            @removeChipFilter="removeFilter"
            @filterChips="filterChips = $event"
            @reloadupdateChips="resetChips"
            dataTestLocation="incident-charting"
        >
        </filter-chip>

        <fe-tabs :tabs="tabs" @tabClicked="activeTab = $event" style="height: 65px;"/>

        <div v-if="activeTab.type === 'big-five'" class="d-flex flex-fill flex-column" style="overflow: scroll;">
            <big-five :params="allParams" fromIncidents/>
        </div>

        <div v-if="activeTab.type === 'range'" class="d-flex flex-fill flex-column no-scroll" style="overflow: hidden;">
            <div class="d-flex">
                <v-spacer/>

                <toggle-button
                    v-if="activeTab.type === 'range'"
                    v-model="activeToggle"
                    :items="toggleItems"
                    class="mr-4"
                />
            </div>

            <div class="d-flex flex-fill flex-column no-scroll" style="overflow: hidden;">
                <incident-range :params="allParams" v-if="activeToggle.show === 'charts'" style="overflow: auto;"/>

                <data-wall :params="allParams" v-if="activeToggle.show === 'data-wall'"/>
            </div>
        </div>

        <incident-advanced-search
            v-if="advancedSearch"
            :existing="existing"
            @close="advancedSearch = false"
            @onSearchClick="searchAdvanced"
        />
    </div>
</template>

<script>
import {mapState} from "vuex"
import BigFive from "./BigFive"
import IncidentAdvancedSearch from "./IncidentAdvancedSearch"
import IncidentRange from './IncidentRange'
import ToggleButton from '@/components/common/button/ToggleButton'
import DataWall from './DataWall'
import {FunctionalCalendar} from 'vue-functional-calendar'
import FilterChip from '@/components/common/FilterChips.vue'

export default {
    name: "IncidentCharting",

    components: {
        BigFive,
        IncidentAdvancedSearch,
        IncidentRange,
        ToggleButton,
        DataWall,
        FunctionalCalendar,
        FilterChip,
    },

    props: {
        chartParams: {
            required: true
        },
        chartFilters: {
            required: true
        },
        windowed: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            localParams: {},
            localFilters: {},
            advancedSearch: false,
            customDateRange: false,
            dateMenuOpen: false,
            selectedDateRange: {
                dateRange: {
                    start: null,
                    end: null
                }
            },
            activeTab: {
                name: 'Big Five',
                path: '#',
                type: 'big-five'
            },
            tabs: [{
                name: 'Big Five',
                path: '#',
                type: 'big-five'
            }, {
                name: 'Range Charts',
                path: '#',
                type: 'range'
            }],
            toggleItems: [{
                name: 'Charts',
                show: 'charts',
                icon: 'fas fa-chart-bar'
            }, {
                name: 'Data Wall',
                show: 'data-wall',
                icon: 'fas fa-table'
            }],
            dateRangeOptions: [{
                id: 1,
                name: 'Yesterday',
                handler: () => {
                    this.selectedDateRange.dateRange.start = this.$dayjs().subtract(1, 'd').format('YYYY-MM-DD')
                    this.selectedDateRange.dateRange.end = this.$dayjs().subtract(1, 'd').format('YYYY-MM-DD')
                    this.setDateRange()
                }
            }, {
                id: 2,
                name: 'This Week',
                handler: () => {
                    this.selectedDateRange.dateRange.start = this.$dayjs().startOf('w').format('YYYY-MM-DD')
                    this.selectedDateRange.dateRange.end = this.$dayjs().endOf('w').format('YYYY-MM-DD')
                    this.setDateRange()

                }
            }, {
                id: 3,
                name: 'Last Week',
                handler: () => {
                    this.selectedDateRange.dateRange.start = this.$dayjs().subtract(1, 'w').startOf('week').format('YYYY-MM-DD')
                    this.selectedDateRange.dateRange.end = this.$dayjs().subtract(1, 'w').endOf('week').format('YYYY-MM-DD')
                    this.setDateRange()
                }
            }, {
                id: 4,
                name: 'Last 2 Weeks',
                handler: () => {
                    this.selectedDateRange.dateRange.start = this.$dayjs().subtract(2, 'w').startOf('week').format('YYYY-MM-DD')
                    this.selectedDateRange.dateRange.end = this.$dayjs().subtract(1, 'w').endOf('week').format('YYYY-MM-DD')
                    this.setDateRange()
                }
            }, {
                id: 5,
                name: 'Last Month',
                handler: () => {
                    this.selectedDateRange.dateRange.start = this.$dayjs().subtract(1, 'M').startOf('month').format('YYYY-MM-DD')
                    this.selectedDateRange.dateRange.end = this.$dayjs().subtract(1, 'M').endOf('month').format('YYYY-MM-DD')
                    this.setDateRange()
                }
            }, {
                id: 6,
                name: 'Custom...',
                handler: () => {
                    this.customDateRange = true
                }
            }, {
                id: 7,
                name: 'Clear',
                last: true,
                handler: () => {
                    this.resetDateRange()
                }
            }],
            activeToggle: {},
            quickFilters: {},
            moreFilters: {},
            updateChips: false,
            filterChips: [],
            fromLaunchpad: false,
        }
    },

    computed: {
        ...mapState('global', ['defaultSchool']),

        existing() {
            let hash = {}
            let params = {}

            Object.keys(this.localParams).forEach(itm => {
                let item = this.localParams[itm]
                if (item !== null) {
                    let key = itm === 'student_active_flag' ? 'active_status' : itm === 'include_secondary_behaviors_responses' ? 'include_only_primary_incidents' : itm
                    params[key] = item
                    if (!['school_year_id', 'start_date', 'end_date', 'active_as_of', 'range'].includes(itm)) {
                        if (!this.$_.isArray(item)) {
                            item = [item]
                        } 
                        hash[key] = item.map(x => {
                            if (['ethnicity', 'gender','observer_user_full_name'].includes(itm)) {
                                return x
                            }
                            return x.hasOwnProperty('id') ? parseInt(x.id) : parseInt(x)
                        })
                    }
                }
            })

            let filters = this.localFilters.filter(itm => {
                if(itm.hasOwnProperty('demographicId')) {
                    return itm
                } else if (params[itm.key] !== null) {
                    if (hash[itm.key].includes(itm.id)) {
                        hash[itm.key] = hash[itm.key].filter(x => x !== itm.id)
                        return itm
                    }
                }  
                return false
            })

            Object.keys(hash).forEach(itm => {
                if (hash[itm].length) {
                    filters.push(...hash[itm].map(x => {
                        return {
                            id: x,
                            key: itm,
                            name: this.localParams[itm] && this.localParams[itm].find(y => y.id === x) ? this.localParams[itm].find(y => y.id === x).name : x
                        }
                    }))
                }
            })
            
            return {
                params,
                filters
            }
        },

        schoolYear: {
            get() {
                return {
                    included: this.localParams.school_year_id
                }
            },
            set(val) {
                this.setParam('school_year_id', val)
            }
        },

        dateRange: {
            get() {
                if (this.localParams.start_date && this.localParams.end_date) {
                    return {
                        multipleDateRange: [],
                        selectedDate: false,
                        selectedDateTime: false,
                        selectedDates: [],
                        selectedDatesItem: "",
                        selectedHour: "00",
                        selectedMinute: "00",
                        dateRange: {
                            start: this.localParams.start_date,
                            end: this.localParams.end_date
                        }
                    }
                } else {
                    return undefined
                }
            },
            set() {
                /**
                 * Not actually setting a value so that it only sets when the user clicks 'Apply'
                 */
            }
        },

        dateRangeActive() {
            if (this.localParams.start_date && this.localParams.end_date) {
                return `${this.localParams.start_date} - ${this.localParams.end_date}`
            }

            return undefined
        },

        school: {
            get() {
                return {
                    included: this.localParams.school_id
                }
            },
            set(val) {
                this.setParam('school_id', val)
            }
        },

        grade: {
            get() {
                return {
                    included: this.localParams.grade_id
                }
            },
            set(val) {
                this.setParam('grade_id', val)
            }
        },

        incidentType: {
            get() {
                return {
                    included: this.localParams.incident_behavior_type_id
                }
            },
            set(val) {
                this.setParam('incident_behavior_type_id', val)
            }
        },

        incidentBehavior: {
            get() {
                return {
                    included: this.localParams.incident_behavior_id
                }
            },
            set(val) {
                this.setParam('incident_behavior_id', val)
            }
        },

        incidentResponse: {
            get() {
                return {
                    included: this.localParams.incident_response_id
                }
            },
            set(val) {
                this.setParam('incident_response_id', val)
            }
        },

        filterButtonText() {
            let exclude = ['school_id', 'grade_id', 'incident_behavior_type_id', 'incident_behavior_id', 'school_year_id', 'start_date', 'end_date']
            let moreFilters = this.localFilters.filter(itm => !exclude.includes(itm.key))
            return `More filters` + (moreFilters.length > 0 ? ` - ${moreFilters.length}` : '')
        },

        schoolURL() {
            if (this.schoolYearParams) {
                return 'schools.php?action=get&' + this.schoolYearParams + '&incl_groups=1'
            }
            return null
        },

        gradeURL() {
            if (this.schoolYearParams) {
                return 'grades.php?action=get&' + this.schoolYearParams
            }
            return null
        },

        schoolYearParams() {
            let year = this.schoolYear.included || []
            let school = this.localParams.school?.included || []
            let p = {
                school_year_id: year.length ? year.map(r => r.id) : '',
                school_id: school.length ? school.map(r => r.id) : '',
            }
            return this.$objectToParams(p)
        },

        ifMenuOpenClass() {
            return this.dateRange ? 'fe-filter-btn-active' : 'fe-filter-btn-btn'
        },

        cssVars() {
            return {
                '--primary-color': this.$vuetify.theme.themes.light.primary.base,
                '--primary-color-dark': this.$vuetify.theme.themes.light.primary.darken1,
                '--primary-color-light2': this.$vuetify.theme.themes.light.primary.lighten2
            }
        },

        allParams() {
            let obj = {}

            Object.keys(this.localParams).forEach(itm => {
                if (this.$_.isArray(this.localParams[itm])) {
                    obj[itm] = this.localParams[itm].map(x => x.hasOwnProperty('id') ? x.id : x)
                } else {
                    switch (itm) {
                        case 'start_date':
                        case 'end_date':
                            if (this.localParams[itm]) {
                                obj[itm] = this.$dayjs(this.localParams[itm]).format('YYYY-MM-DD')
                            }
                            break
                        default:
                            obj[itm] = this.localParams[itm]
                    }
                }
            })

            this.localFilters.forEach(itm => {
                if (!obj[itm.key] && itm.key !== 'active_status') {
                    obj[itm.key] = itm.id
                }

                // handle display_name_group, if exists
                if (['ethnicity','gender','disability_type_id','meal_status_id','ell_level_id'].includes(itm.key)) {
                    if (itm.display_name_group) {
                        itm.display_name_group.forEach(n => {
                            if (obj[itm.key].indexOf(n) === -1) {
                                obj[itm.key].push(n)
                            }
                        })
                    }
                }
            })

            return obj
        }
    },

    created() {
        this.activeToggle = this.toggleItems[0]
        this.searchAdvanced(this.$_.cloneDeep(this.chartParams), this.$_.cloneDeep(this.chartFilters))
    },

    mounted() {
        let toolIcons = document.getElementsByClassName('fe-window-tools-icon')
        toolIcons.forEach(icon => {
            switch(icon.innerHTML) {
                case 'chevron_left':
                    icon.setAttribute('data-test', 'launchpad-incidents-overlay-left-arrow')
                    break
                case 'chevron_right':
                    icon.setAttribute('data-test', 'launchpad-incidents-overlay-right-arrow')
                    break
                case 'minimize':
                    icon.setAttribute('data-test', 'launchpad-incidents-overlay-minimize')
                    break
                case 'close':
                    icon.setAttribute('data-test', 'launchpad-incidents-overlay-close')
                    break
                default:
                    break
            }
        })
        this.addDataTest()
        if(Object.keys(this.$route?.params).length) {
            this.searchAdvanced(this.$route.params.chartParams, this.$route.params.chartFilters)
            this.fromLaunchpad = this.$route.params.location === 'launchpad' ? true : false
        }
    },

    methods: {
        addDataTest() {
            let tabs = document.getElementsByClassName('v-tab')
            tabs.forEach(tab => {
                let tabName = tab.innerText.toLowerCase().replace(' ', '-')
                tab.setAttribute('data-test', `${tabName}-tab`)
            })

            let backBtn = document.getElementById('incidents-charting-back-btn')
            if (backBtn) backBtn.getElementsByTagName('button')[0].setAttribute('data-test', 'incidents-charting-back-btn')
        },

        searchAdvanced(chartParams, chartFilters) {
            if (chartParams.start_date) {
                chartParams.start_date = this.$dayjs(chartParams.start_date).format('M/D/YYYY')
            } else {
                chartParams.start_date = ''
            }
            if (chartParams.end_date) {
                chartParams.end_date = this.$dayjs(chartParams.end_date).format('M/D/YYYY')
            } else if (chartParams.range && chartParams.start_date) {
                chartParams.end_date = this.$dayjs().format('M/D/YYYY')
            } else {
                chartParams.end_date = ''
            }

            let quickFilters = {
                school_id: [],
                grade_id: [],
                incident_behavior_type_id: [],
                incident_behavior_id: [],
                incident_response_id: [],
                date_range: [],
            }
            this.moreFilters = {}

            let start, end
            let exclude = ['school_id', 'grade_id', 'incident_behavior_type_id', 'incident_behavior_id', 'school_year_id', 'incident_response_id', 'include_secondary_behaviors_responses', 'start_date', 'end_date']
            Object.keys(chartParams).forEach(param => {
                if(param === 'range') return
                let filter = chartFilters.length && chartFilters.filter(x => x.key === param).length ? chartFilters.filter(x => x.key === param) : chartParams[param] 
                if(!exclude.includes(param)) {
                    this.moreFilters = { ...this.moreFilters, [param]: filter }
                }
                Object.keys(quickFilters).forEach(quick => {
                    if(param === quick) {
                        if(this.$_.isArray(filter)) {
                            quickFilters[quick] = filter
                        } else {
                            quickFilters[quick].push(filter)
                        } 
                    }
                    if(param === 'start_date') {
                        start = filter
                    }
                    if(param === 'end_date') {
                        end = filter
                    }
                })
            })
            quickFilters.date_range = start || end ? [{ name: start + ' - ' + end }] : []
            chartFilters.forEach(f => {
                if(f.demographicId) {
                    this.moreFilters = { ...this.moreFilters, [f.key]: f.id }
                }
            })

            this.localParams = chartParams
            this.localFilters = chartFilters
            this.quickFilters = quickFilters

            this.advancedSearch = false
            this.updateChips = true
        },

        setDateRange() {
            let localParams = this.$_.cloneDeep(this.localParams)
            localParams.start_date = this.$dayjs(this.selectedDateRange.dateRange.start).format('M/D/YYYY')
            localParams.end_date = this.$dayjs(this.selectedDateRange.dateRange.end).format('M/D/YYYY')
            this.localParams = localParams
            this.searchAdvanced(this.localParams, this.localFilters)
            this.closeDateRange()
        },

        setParam(key, val) {
            let localParams = this.$_.cloneDeep(this.localParams)
            localParams[key] = val.hasOwnProperty('included') ? val.included : val
            this.localParams = localParams
            this.localFilters = this.localFilters.filter(obj => obj.key != key)
            this.searchAdvanced(this.localParams, this.localFilters)
        },

        resetDateRange() {
            this.localParams.start_date = undefined
            this.localParams.end_date = undefined
            this.searchAdvanced(this.localParams, this.localFilters)
            this.closeDateRange()
        },

        closeDateRange() {
            this.dateMenuOpen = false
            this.customDateRange = false
        },

        removeFilter(filter, i = null) {
            this.localFilters = this.localFilters.filter(obj => obj.key != filter.name)
            let localParams = this.$_.cloneDeep(this.localParams)
            delete localParams[filter.key]
            this.localParams = localParams

            if (filter.key === 'school_id' && filter.id === this.defaultSchool?.id) {
                this.$store.commit('global/updateSessionFlag', {
                    flag: 'incidentAdvancedSearchPreventDefaultSchool',
                    value: true
                })
            }

            if(filter.name && this.$refs[`${filter.name}-filter-menu`]) {
                this.$refs[`${filter.name}-filter-menu`].clearSelections()
            }

            if(filter.name === 'date_range') {
                this.selectedDateRange = {
                    dateRange: {
                        start: null,
                        end: null
                    }
                }
                this.localParams.start_date = undefined
                this.localParams.end_date = undefined
            }
            this.searchAdvanced(this.localParams, this.localFilters)
        },
        clearAllFilters() {
            this.quickFilters = {
                school_id: [],
                grade_id: [],
                incident_behavior_type_id: [],
                incident_behavior_id: [],
                incident_response_id: [],
                date_range: [],
            }
            this.moreFilters = {}
            this.localFilters = []
            Object.keys(this.$refs).forEach(ref => {
                // clear all $refs - don't clear school year!
                if(ref.includes('-filter-menu')) {
                    this.$refs[ref].clearSelections()
                }
            })
            this.selectedDateRange = {
                dateRange: {
                    start: null,
                    end: null
                }
            }
            Object.keys(this.localParams).forEach(key => {
                if(!['school_year_id'].includes(key)) {
                    delete this.localParams[key]
                }
            })
            this.searchAdvanced(this.localParams, this.localFilters)
        },

        resetChips() {
            this.$nextTick(() => {
                this.updateChips = false
            })
        },
        
        goBack() {
            if(this.fromLaunchpad) this.$router.push('/')
            else this.$emit('chart', false)
        }
    },

    watch: {
        'activeTab.type'(v) {
            if(v == 'range') {
                // have to set tiny set timeout as btns don't appear straight away
                setTimeout(function() {
                    let toggleBtnCharts = document.getElementById('toggle-btn-0')
                    let toggleBtnDataWall = document.getElementById('toggle-btn-1')
                    toggleBtnCharts.setAttribute('data-test', 'launchpad-incidents-overlay-charts')
                    toggleBtnDataWall.setAttribute('data-test', 'launchpad-incidents-overlay-data-list')
                }, 10)
            }
        },
        '$route'(to, from) {
            if(Object.keys(this.$route?.params).length) {
                this.searchAdvanced(this.$route.params.chartParams, this.$route.params.chartFilters)
            }
       }
    }
}
</script>

<style lang="scss" scoped>
::v-deep.exclude {
    .d-inline-block div .v-chip .v-chip__content {
        background-color: #f2d5d2 !important;
        color: #000000de;
    }
}

.fe-filter-btn-menu {
    border: 1px solid #ECEDF1;
    box-shadow: 0 1px 5px #9297A6;
    max-width: 350px;

    ::v-deep .v-list-item {
        min-height: 32px !important;
    }
}


.fe-filter-btn-btn {
    background: #fff !important;
    border: 1px solid #E0E1E6;
    font-size: 14px;

    &:hover {
        background: var(--primary-color-light2) !important;
        border: 1px solid #E0E1E6 !important;
    }

    ::v-deep .v-btn__content {
        color: #050F2D;
    }
}

.fe-filter-btn-active {
    background: var(--primary-color-light2) !important;
    border-color: transparent !important;
    color: var(--primary-color-dark) !important;
}

.last-option {
    border-top: 1px solid #E0E1E6;
    padding-top: 8px;
}

.calendar {
    position: absolute;
    z-index: 9;
    left: 7px;
    top: 220px;
    border: 1px solid #ECEDF1;
    box-shadow: 0 1px 5px #9297A6;
    background-color: white;

    &.existing-range {
        left: unset;
    }

    .lean-calendar {
        ::v-deep .vfc-main-container {
            box-shadow: none !important;
        }

        ::v-deep .vfc-main-container {
            font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
            height: 290px;
        }

        ::v-deep .vfc-top-date {
            font-size: 14px !important;
        }

        ::v-deep .vfc-arrow-right, ::v-deep .vfc-arrow-left {
            height: 10px !important;
            width: 10px !important;
        }

        ::v-deep .vfc-dayNames span {
            color: #7E8494 !important;
            margin-bottom: 0 !important;
            font-size: 13px;
        }

        ::v-deep .vfc-today:not(.vfc-marked) {
            background-color: transparent !important;
            color: #000 !important;
            border: 1px solid var(--fe-primary) !important;
        }

        ::v-deep .vfc-week {
            font-size: 13px;
        }

        ::v-deep .vfc-months-container {
            .vfc-content {
                width: 55% !important;
            }
        }

        ::v-deep .vfc-marked {
            background: #CCE2E9 !important;
        }

        ::v-deep .vfc-start-marked {
            background: #006C90 !important;
        }

        ::v-deep .vfc-end-marked {
            background: #006C90 !important;
        }
    }
}
</style>
